<template>
  <div v-if="show" class="little-loading" :class="className">
    <div class="loading-indicator">
      <div class="dot-circle"></div>
      <div class="dot-circle"></div>
      <div class="dot-circle"></div>
      <div class="dot-shadow"></div>
      <div class="dot-shadow"></div>
      <div class="dot-shadow"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true
    },
    className: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped>
.little-loading{
  display: flex;
  justify-content: center;
}
.loading-indicator {
  width: 60px;
  height: 30px;
  position: relative;
  z-index: 4;
}

.dot-circle {
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 50%;
  background-color: #162447;
  left: 15%;
  -webkit-transform-origin: 50%;
      -ms-transform-origin: 50%;
          transform-origin: 50%;
  -webkit-animation: dot-circle7124 0.5s alternate infinite ease;
          animation: dot-circle7124 0.5s alternate infinite ease;
}

@-webkit-keyframes dot-circle7124 {
  0% {
    top: 20px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    -webkit-transform: scaleX(1.7);
            transform: scaleX(1.7);
  }

  40% {
    height: 8px;
    border-radius: 50%;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

@keyframes dot-circle7124 {
  0% {
    top: 20px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    -webkit-transform: scaleX(1.7);
            transform: scaleX(1.7);
  }

  40% {
    height: 8px;
    border-radius: 50%;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.dot-circle:nth-child(2) {
  left: 45%;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.dot-circle:nth-child(3) {
  left: auto;
  right: 15%;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.dot-shadow {
  width: 5px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 30px;
  -webkit-transform-origin: 50%;
      -ms-transform-origin: 50%;
          transform-origin: 50%;
  z-index: 3;
  left: 15%;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  -webkit-animation: dot-shadow046 0.5s alternate infinite ease;
          animation: dot-shadow046 0.5s alternate infinite ease;
}

@-webkit-keyframes dot-shadow046 {
  0% {
    -webkit-transform: scaleX(1.5);
            transform: scaleX(1.5);
  }

  40% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: scaleX(0.2);
            transform: scaleX(0.2);
    opacity: 0.4;
  }
}

@keyframes dot-shadow046 {
  0% {
    -webkit-transform: scaleX(1.5);
            transform: scaleX(1.5);
  }

  40% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: scaleX(0.2);
            transform: scaleX(0.2);
    opacity: 0.4;
  }
}

.dot-shadow:nth-child(4) {
  left: 45%;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.dot-shadow:nth-child(5) {
  left: auto;
  right: 15%;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
</style>
